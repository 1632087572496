import fetch from '@/apis/request'

export default class Apis {
  // 隐患类别统计
  static hiddenDangerCategoryData(data) {
    return fetch.get('/pc/securityCheck/hiddenDangerCategoryData',{
      params: data
    })
  }

  // 复现类别排名
  static repeatCategoryData(data) {
    return fetch.get('/pc/securityCheck/repeatCategoryData',{
      params: data
    })
  }

  // 复现隐患排名
  static repeatHiddenDangerData(data) {
    return fetch.get('/pc/securityCheck/repeatHiddenDangerData',{
      params: data
    })
  }

  // 应用统计
  static projectStatistics(data) {
    return fetch.get('/pc/securityCheck/projectStatistics',{
      params: data
    })
  }

  // 检查排名
  static companyData(data) {
    return fetch.get('/pc/securityCheck/companyData',{
      params: data
    })
  }

}
