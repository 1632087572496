<template>
  <div class="repeat-category-statistics flex">
    <div class="flex-column" style="width: 50%; height: 100%;">
      <div class="nameTit flex-space-between">
        <div class="tp-left flex-center-v">
          <span style="margin-right: 10px;">复现类别排名</span>
          <el-select v-model="searchData.level" placeholder="检查状态" size="small" style="width:160px;margin-right:15px;">
            <el-option label="一级" :value="1"></el-option>
            <el-option label="二级" :value="2"></el-option>
            <el-option label="三级" :value="3"></el-option>
            <el-option label="四级" :value="4"></el-option>
          </el-select>
          <el-button type="primary" size="small" style="width:80px;" @click="search">查询</el-button>
        </div>
      </div>
      <div class="cont flex-1-auto">
        <div class="flex-center" style="width: 100%;height: 100%;overflow: hidden;">
          <div id="zhu1" style="width: 90%;height: 500px;"></div>
        </div>
      </div>
    </div>
    <div style="width: 1px; height: 100%;margin: 0 10px;border-right: 1px dashed #cdcdcd;"></div>
    <div class="flex-column" style="width: 50%; height: 100%;">
      <div class="nameTit flex-space-between">
        <div class="tp-left flex-center-v">
          <span style="margin-right: 10px;">复现隐患排名</span>
        </div>
      </div>
      <div class="cont flex-1-auto">
        <div class="flex-center" style="width: 100%;height: 100%;overflow: hidden;">
          <div id="zhu2" style="width: 90%;height: 500px;"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import apis from "./apis3";
import * as echarts from "echarts";

export default {
  name: "repeat-category-statistics",

  components: {},

  props: {},

  data() {
    return {
      searchData: {
        level: 1
      }
    }
  },

  computed: {},

  watch: {},

  mounted() {
    this.init1().async
    this.init2().async
  },

  methods: {
    async init1() {
      const result = await apis.repeatCategoryData(this.searchData)
      this.initEcharts1(result)
    },
    async init2() {
      const result = await apis.repeatHiddenDangerData()
      this.initEcharts2(result)
    },
    initEcharts1(data) {
      let chartDom = document.getElementById('zhu1');
      let myChart = echarts.init(chartDom);
      let option = {
        color: ['#3C5CFF'],
        tooltip: {
          trigger: 'axis',
          axisPointer: {            // 坐标轴指示器，坐标轴触发有效
            type: 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
          }
        },
        legend: {
          left: 10
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '10%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            data: data.name,
            axisTick: {
              alignWithLabel: true
            },
            axisLabel: {
              interval:0,
              rotate:40,
              formatter: function (value) {
                if (value !== undefined) {
                  if (value.length > 4) {      // 超出5个字符显示省略号
                    return `${value.slice(0, 5)}...`;
                  }
                  return value;
                }
              }
            }
          }
        ],
        yAxis: {
          axisTick: {
            show: true
          },
          axisLine: {
            show: true,
          },
          splitLine: {    //网格线
            lineStyle: {
              type: 'dashed'    //设置网格线类型 dotted：虚线   solid:实线
            },
            show: true //隐藏或显示
          }
        },
        dataZoom: {
          show: data.name.length > 11 ? true : false, // 为true 滚动条出现
          realtime: true, // 实时更新
          type:'slider', // 有type这个属性，滚动条在最下面，也可以不行，写y：36，这表示距离顶端36px，一般就是在图上面。
          height: 12, // 表示滚动条的高度，也就是粗细
          startValue: 0, // 从头开始。
          endValue: 10,  // 一次性展示6个。
          zoomLock: false // 是否锁定选择区域（或叫做数据窗口）的大小。
        },
        series: [
          {
            name: '隐患总计',
            type: 'bar',
            barWidth: '35',
            data: data.value
          }
        ]
      };
      option && myChart.setOption(option)
    },
    initEcharts2(data) {
      let chartDom = document.getElementById('zhu2');
      let myChart = echarts.init(chartDom);
      let option = {
        color: ['#3C5CFF'],
        tooltip: {
          trigger: 'axis',
          axisPointer: {            // 坐标轴指示器，坐标轴触发有效
            type: 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
          }
        },
        legend: {
          left: 10
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '10%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            data: data.name,
            axisTick: {
              alignWithLabel: true
            },
            axisLabel: {
              interval:0,
              rotate:40,
              formatter: function (value) {
                if (value !== undefined) {
                  if (value.length > 4) {      // 超出5个字符显示省略号
                    return `${value.slice(0, 5)}...`;
                  }
                  return value;
                }
              }
            }
          }
        ],
        yAxis: {
          axisTick: {
            show: true
          },
          axisLine: {
            show: true,
          },
          splitLine: {    //网格线
            lineStyle: {
              type: 'dashed'    //设置网格线类型 dotted：虚线   solid:实线
            },
            show: true //隐藏或显示
          }
        },
        dataZoom: {
          show: data.name.length > 11 ? true : false, // 为true 滚动条出现
          realtime: true, // 实时更新
          type:'slider', // 有type这个属性，滚动条在最下面，也可以不行，写y：36，这表示距离顶端36px，一般就是在图上面。
          height: 12, // 表示滚动条的高度，也就是粗细
          startValue: 0, // 从头开始。
          endValue: 10,  // 一次性展示6个。
          zoomLock: true // 是否锁定选择区域（或叫做数据窗口）的大小。
        },
        series: [
          {
            name: '隐患总计',
            type: 'bar',
            barWidth: '35',
            data: data.value
          }
        ]
      };
      option && myChart.setOption(option)
    },
    // 查询
    search() {
      this.init1().async
    }
  }
}
</script>

<style lang="stylus" scoped>
.repeat-category-statistics {
  height 100%
  padding 10px
}
</style>
